import { BaseIconPathStyle, BaseSvgStyle } from '../IconBaseStyle';
import { ComponentProps } from '../SVGBase';

function EyeOpen({ size, color }: ComponentProps) {
  return (
    <BaseSvgStyle
      size={size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <BaseIconPathStyle
        stroke={color}
        strokeRound
        d="M11.9998 5.25096C7.96883 5.18296 3.79983 7.99996 1.17883 10.885C0.903711 11.1904 0.751465 11.5869 0.751465 11.998C0.751465 12.409 0.903711 12.8055 1.17883 13.111C3.74283 15.935 7.89983 18.817 11.9998 18.748C16.0998 18.817 20.2578 15.935 22.8238 13.111C23.0989 12.8055 23.2512 12.409 23.2512 11.998C23.2512 11.5869 23.0989 11.1904 22.8238 10.885C20.1998 7.99996 16.0308 5.18296 11.9998 5.25096Z"
      />
      <BaseIconPathStyle
        stroke={color}
        strokeRound
        d="M15.75 12C15.7498 12.7417 15.5297 13.4666 15.1175 14.0831C14.7054 14.6997 14.1196 15.1802 13.4344 15.4639C12.7491 15.7475 11.9952 15.8217 11.2678 15.6768C10.5404 15.532 9.87234 15.1748 9.348 14.6503C8.82365 14.1258 8.4666 13.4576 8.32198 12.7302C8.17737 12.0028 8.25169 11.2489 8.53555 10.5637C8.81941 9.87857 9.30005 9.29295 9.91672 8.88094C10.5334 8.46893 11.2584 8.24902 12 8.24902C12.4926 8.24889 12.9804 8.34583 13.4355 8.5343C13.8905 8.72277 14.304 8.99908 14.6523 9.34743C15.0006 9.69579 15.2768 10.1094 15.4651 10.5645C15.6535 11.0196 15.7503 11.5074 15.75 12Z"
      />
    </BaseSvgStyle>
  );
}

export default EyeOpen;
